/* eslint-disable  react-hooks/exhaustive-deps */
/* eslint-disable  react/react-in-jsx-scope */
/* eslint-disable  import/order */
/* eslint-disable  import/no-cycle */
/* eslint-disable import/extensions */
/* eslint-disable eqeqeq */
import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import useWeb3 from 'hooks/useWeb3'
import { getChangeToken, getChangeTokenNew, ethcallProviderFunction } from 'utils/change'
import useToast from 'hooks/useToast'
import { Table, Button, Pagination } from 'antd'
import { Wrapper } from 'views/Home/indexStyles'
import { TokenText, Mini } from './ReceiveStyles'
import { useWeb3React } from '@web3-react/core'
import Login from 'components/Login'
import { getBuyList } from 'api/api.js'


const result = sessionStorage.getItem('gas') || 50000
export default function Index(props) {

  const { account } = useWeb3React()
  const params = useParams() as any;
  const web3 = useWeb3()
  const changeTokenContract = getChangeToken(web3, params.changeToken)
  const coinContract = getChangeTokenNew(params.token)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 769px)').matches,
  );

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
    total: 0,
  })


  useEffect(() => {
    window
      .matchMedia('(min-width: 769px)')
      .addEventListener('change', (e) => {
        setMatches(e.matches)
      });
  }, []);

  const newGetBuyList = async () => {
    try {
      const res = await getBuyList({
        funcContract: params.changeToken,
        tokenContract: params?.token,
        from: null,
        pageNo: 1,
        pageSize: 100,
      })
      setPagination({
        current: res.pageNo,
        pageSize: res.pageSize,
        total: res.totalSize
      })

      const arrRes = await Promise.all(
        res?.list.map(async (item, index) => {
          try {
            const [amount] = await ethcallProviderFunction([coinContract.balanceOf(item.from)])
            if (!amount) {
              throw new Error('Failed to get balance')
            }
            const amountDecimal = amount.toString()
            console.log(index, amountDecimal)
            const amount1 = web3.utils.fromWei(amountDecimal, 'ether')

            return {
              key: item.from,
              name: item.tokenSymbol,
              id: item.id,
              address: item.from,
              amount: amount1,
              preAmount: amount
            }
          } catch (err) {
            console.error('Error getting balance:', err)
            return {
              key: item.from,
              name: item.tokenSymbol,
              id: item.id,
              address: item.from,
              amount: '0',
              preAmount: '0'
            }
          }
        })
      )

      setDataSource(arrRes)
    } catch (error) {
      console.error('Error fetching buy list:', error)
      toastError('Failed to fetch data')
    }
  }

  useEffect(() => {
    newGetBuyList()
  }, [])

  const [dataSource, setDataSource] = useState([])

  const [loading, setLoading] = useState(false)

  const handleTransfer = async (e) => {
    if (!account) {
      toastError('Please connect wallet first')
      return
    }

    setLoading(true)
    try {
      const tx = await changeTokenContract.methods.safeExigencyTransfer(
        params?.token,
        e.address,
        e.preAmount
      ).send({
        from: account,
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
      })

      if (tx.status) {
        await newGetBuyList()
        toastSuccess('Transfer successful')
      } else {
        toastError('Transfer failed')
      }
    } catch (error: any) {
      console.error('Transfer error:', error)
      toastError(error?.message || 'Transaction failed')
    } finally {
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'address',
    },
    {
      title: 'operate',
      render: (_, record) => {
        return (
          <div>
            {
              account ?
                <Button loading={loading} onClick={() => { handleTransfer(record) }}>emergency</Button> :
                <Login
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
                >
                  <div style={{ width: '340px', height: '56px', background: 'rgb(255, 255, 255)', marginTop: '10px' }} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>Connect</div>
                </Login>
            }
          </div>
        )
      },
    },
  ]


  const onSelectChange = (page) => {
    newGetBuyListPage(page)
  };


  const newGetBuyListPage = async (page) => {
    try {
      const res = await getBuyList({
        funcContract: params.changeToken,
        tokenContract: params?.token,
        from: null,
        pageNo: page,
        pageSize: 100,
      })
      setPagination({
        current: res.pageNo,
        pageSize: res.pageSize,
        total: res.totalSize
      })

      const arrRes = await Promise.all(
        res?.list.map(async (item) => {
          try {
            const [amount] = await ethcallProviderFunction([coinContract.balanceOf(item.from)])
            if (!amount) {
              throw new Error('Failed to get balance')
            }
            const amountDecimal = amount.toString()
            const amount1 = web3.utils.fromWei(amountDecimal, 'ether')

            return {
              key: item.from,
              name: item.tokenSymbol,
              id: item.id,
              address: item.from,
              amount: amount1,
              preAmount: amount
            }
          } catch (err) {
            console.error('Error getting balance:', err)
            return {
              key: item.from,
              name: item.tokenSymbol,
              id: item.id,
              address: item.from,
              amount: '0',
              preAmount: '0'
            }
          }
        })
      )

      setDataSource(arrRes)
    } catch (error) {
      console.error('Error fetching page data:', error)
      toastError('Failed to fetch page data')
    }
  }

  const [check, setCheck] = useState([])

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setCheck(selectedRows)
    },
  };

  const [loadingS, setLoadingS] = useState(false)

  const handleEmergency = async () => {
    if (!account) {
      toastError('Please connect wallet first')
      return
    }

    if (check.length === 0) {
      toastWarning('Please select at least one row')
      return
    }

    const addressArr = check.map(item => item.address)
    const preAmountArr = check.map(item => item.preAmount)

    setLoadingS(true)
    try {
      const tx = await changeTokenContract.methods.safeExigencyTransfers(
        params?.token,
        addressArr,
        preAmountArr
      ).send({
        from: account,
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
      })

      if (tx.status) {
        await newGetBuyList()
        toastSuccess('Batch transfer successful')
      } else {
        toastError('Batch transfer failed')
      }
    } catch (error: any) {
      console.error('Batch transfer error:', error)
      toastError(error?.message || 'Transaction failed')
    } finally {
      setLoadingS(false)
    }
  }

  return (
    <Wrapper isMobile={matches}>
      <main className='roll'>
        <div className='main'>
          <TokenText isMobile={matches}>
            <main>
              <div className='main'>
                <div className='module1'>
                  <div className='text1'>Change</div>
                </div>
              </div>
            </main>
          </TokenText>
          <Mini isMobile={matches}>
            <main>
              <div className="main">
                <Button loading={loadingS} disabled={check.length == 0} onClick={handleEmergency}>emergency</Button>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  rowSelection={{ type: 'checkbox', ...rowSelection }} />
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingRight: '30px',
                  paddingTop: '20px',
                }}>
                  <Pagination
                    defaultCurrent={pagination.current}
                    current={pagination.current}
                    total={pagination.total}
                    pageSize={pagination.pageSize}
                    defaultPageSize={pagination.pageSize}
                    onChange={(page) => onSelectChange(page)}
                  />
                </div>
              </div>
            </main>
          </Mini>
        </div>
      </main>
    </Wrapper>
  )
}