/* eslint-disable import/prefer-default-export */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

const StyleBase = styled.div``

const TokenText = styled(StyleBase)<{isMobile?: boolean}>`
  position: relative;
  display: flex;
  justify-content: start;
  main {
    width: 100%;
    .main {
      width: 100%;
      .module1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        .text1 {
          text-align: center;
          font-size: ${({isMobile}) => isMobile ?  '40px' : '32px' };
          font-family: Nirmala UI-Bold;
          font-weight: 400;
          color: #FFFFFF;
        }
        .text2-box {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          margin-top: 29px;
          width: ${({isMobile}) => isMobile ?  '500px' : '100%' };
          .text2-1 {
            font-size: ${({isMobile}) => isMobile ?  '20px' : '20px' };
            font-family: Nirmala UI-Bold;
            font-weight: 400;
            color: #FFFFFF;
          }
          .text2-2 {
            font-size:  ${({isMobile}) => isMobile ?  '20px' : '24px' };
            font-family: Consolas-Regular, Consolas;
            font-weight: 400;
            color: #FACE9C;
          }
        }
      }
    }
  }
`
const Mini = styled(StyleBase)<{isMobile?: boolean}>`
  position: relative;
  display: flex;
  justify-content: start;
  main {
    width: 100%;
    .main { 
      display: flex;
      flex-direction: ${({isMobile}) => isMobile ?  'row' : 'column' };
      justify-content: ${({isMobile}) => isMobile ?  'space-evenly' : '' };
      align-items: ${({isMobile}) => isMobile ?  'center' : '' };
      margin-top: 58px;
      background: #232323;
      border-radius: 16px 16px 16px 16px;
      opacity: 1;
      padding: 80px 0;
      padding-left: ${({isMobile}) => isMobile ?  '0' : '0' };
      .main-left,.main-left-b {
        width: ${({isMobile}) => isMobile ?  '380px' : '100%' };
        padding: ${({isMobile}) => isMobile ?  ' ' : '0 16px' };
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;   
        >div {
          width: 100%;
        } 
        .img {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 21px;
          img {
            width: 291px;
            height: 291px;
          }
        }
        .mini-text-box {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          font-family: Nirmala UI-Regular, Nirmala UI;
          font-weight: 400;
          line-height: 32px;
          .mini-text-left {
            color: #8A8A8A;
          }
          .mini-text-right {
            color: #EEEEEE;
          }
        }
      }
      .main-left-b {
        width: ${({isMobile}) => isMobile ?  '350px' : '100%' };

      }
      .main-right {
        padding: ${({isMobile}) => isMobile ?  ' ' : '0 16px' };
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;    
        .mini-main-text-box {
          margin-top: ${({isMobile}) => isMobile ?  '' : '' };
          width: ${({isMobile}) => isMobile ?  '340px' : '100%' };
          font-size: 14px;
          font-family: Nirmala UI-Regular, Nirmala UI;
          font-weight: 400;
          color: #999999;
          line-height: 32px;
          display: flex;
          justify-content: flex-end;
          text-align: center;
        }
        input {
          width: ${({isMobile}) => isMobile ?  '340px' : '100%' };
          height: 56px;
          background: #1A1A1A;
          border-radius: 12px 12px 12px 12px;
          border: 1px solid #323232;
          color: #8A8A8A;
          padding-right: 135px;
        }
        input::-webkit-input-placeholder {
          color: #8A8A8A;
        }
        input:-moz-placeholder {
          color: #8A8A8A;
        }
        input::-moz-placeholder {
          color: #8A8A8A;
        }
        input:-moz-input-placeholder {
          color: #8A8A8A;
        }
        .input-box {
          margin-top: ${({isMobile}) => isMobile ?  '22px' : '15px' };
          width: ${({isMobile}) => isMobile ?  '' : '100%' };
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: ${({isMobile}) => isMobile ?  'column' : 'row' };

        }
        .btm {
          margin-top: ${({isMobile}) => isMobile ?  '21px' : '46px' };
          width: ${({isMobile}) => isMobile ?  '579px' : '100%' };
          display: flex;
          flex-direction: ${({isMobile}) => isMobile ?  'row' : 'column' };
          justify-content: space-between;
          align-items: center;
          .btn {
            margin-bottom: ${({isMobile}) => isMobile ?  '' : '16px' };
            width: ${({isMobile}) => isMobile ?  '170px' : '100%' };
            button {
              background: #232323;
              width: ${({isMobile}) => isMobile ?  '170px' : '100%' };
              height: 56px;
              border-radius: 12px 12px 12px 12px;
              opacity: 1;
              border: 1px solid #EFEFEF;
              font-size: 16px;
              font-family: Nirmala UI-Regular, Nirmala UI;
              font-weight: 400;
              color: #EFEFEF;
            }
          }
        }
        .isCoin3 {
          justify-content: center;
        }
        .text-success {
          margin-top: 16px;
          width: ${({isMobile}) => isMobile ?  '579px' : '100%' };
          height: 45px;
          font-size: 16px;
          font-family: Nirmala UI-Regular, Nirmala UI;
          font-weight: 400;
          color: #999999;
          line-height: 24px;
        }
        .text-success2 {
          margin-top: 48px;

        }
        .input-box-success {
          margin-top: 22px;
          width: ${({isMobile}) => isMobile ?  '579px' : '100%' };
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: ${({isMobile}) => isMobile ?  'row' : 'row' };

        }
        .tok-title {
          font-size: 24px;
          font-family: Nirmala UI-Regular, Nirmala UI;
          font-weight: 400;
          color: #EEEEEE;
          line-height: 32px;
        }
        .ipt1-title {
          margin-top: 16px;
          width: ${({isMobile}) => isMobile ?  '340px' : '100%' };
          font-size: 16px;
          font-family: Nirmala UI-Regular, Nirmala UI;
          font-weight: 400;
          color: #EEEEEE;
          line-height: 32px;
        }
        .ipt2-title {
          margin-top: 9px;
          width: ${({isMobile}) => isMobile ?  '340px' : '100%' };
          font-size: 16px;
          font-family: Nirmala UI-Regular, Nirmala UI;
          font-weight: 400;
          color: #EEEEEE;
          line-height: 32px;
          
        }
        .ipt1 {
          position: relative;
          width: ${({isMobile}) => isMobile ?  '' : '100%' };
          margin-top: 12px;
          input {
            padding-left: 42px;
          }
          .img {
            position: absolute;
            z-index: 2;
            top: 8px;
            left: 8px;
            wodth: 20px;
            height: 20px;
          }
          .icon-box {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            position: absolute;
            width: 140px;
            right: 0px;
            top: 18px;
          }
          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            line-height: 21px;
          }
          button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 46px;
            height: 21px;
            background: #232323;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #323232;
            font-size: 16px;
            font-family: Nirmala UI-Regular, Nirmala UI;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
          }
        }
        .ipt2 {
          position: relative;
          width: ${({isMobile}) => isMobile ?  '' : '100%' };
          margin-top: 12px;
          .img {
            position: absolute;
            z-index: 2;
            top: 8px;
            left: 8px;
            wodth: 20px;
            height: 20px;
          }
          .ipt-i {
            margin-left: 5px;
            position: absolute;
            right: 14px;
            top: 20px;
          }
          .ipt2-c {
            width: ${({isMobile}) => isMobile ?  '340px' : '100%' };
            height: 56px;
            background: #1A1A1A;
            border-radius: 12px 12px 12px 12px;
            border: 1px solid #323232;
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 12px;
            padding-right: 81px;
            padding-left: 42px;
          }
        }
        .tok-bottom {
          width: ${({isMobile}) => isMobile ?  '340px' : '100%' };
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          font-family: Nirmala UI-Regular, Nirmala UI;
          font-weight: 400;
          line-height: 16px;
          color: #FFFFFF;
        }
        .line {
          width: ${({isMobile}) => isMobile ?  '340px' : '100%' };
          margin-top: 12px;
          border-bottom: 1px solid #707070;
        }
        .ch-ipt {
          margin-top: 0;
          width: ${({isMobile}) => isMobile ?  'auto' : 'auto' };
        }
        .img {
          margin-top: 5px;
          img {
            width: auto;
            height: 28.69px;
          }
        }
        .btn {
          width: ${({isMobile}) => isMobile ?  '' : '100%' };
          margin-top: 23px;
          button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: ${({isMobile}) => isMobile ?  '340px' : '100%' };
            height: 56px;
            background: #FFFFFF;
            border-radius: 12px 12px 12px 12px;
            font-size: 16px;
            font-family: Nirmala UI-Regular, Nirmala UI;
            font-weight: 400;
            color: #000000;
          }
        }
        .btn-change {
          margin-top: ${({isMobile}) => isMobile ?  '86px' : '41px' };
          display: flex;
          width: ${({isMobile}) => isMobile ?  '400px' : '100%' };
          justify-content: space-between;
          flex-direction: ${({isMobile}) => isMobile ?  'row' : 'column' };      
          button {
            width: ${({isMobile}) => isMobile ?  '170px' : '100%' };
            height: 56px;
            border-radius: 12px 12px 12px 12px;
            opacity: 1;
          }
          .Cancle {
            background: #F8C6C6;
            color: #B11213;
            margin-bottom: ${({isMobile}) => isMobile ?  '' : '15px' };
          }
        }
      }
    }
    .one-page {
      background: rgba(0,0,0,0);
      gap: 20px;
      flex-wrap: wrap;
      .main-left-b {
        width: ${({isMobile}) => isMobile ?  '45%' : '100%' };
        height: 268px;
        border-radius: 12px;
        background: #232323;
        padding: ${({isMobile}) => isMobile ?  '0 24px' : '0 16px' };
        .img {
          position: relative;
          width: 100px;
          height: 100px;
          img {
              width: 100%;
              height: 100%;
          }
          .c1 {
            transform: translateY(-12px) translateX(-32px);
          }
          .c2 {
            position: absolute;
            transform: translateY(12px) translateX(31px);
          }
        }
      }
    }
  }
`

export {
  TokenText, Mini
}